<template>
  <div class="home-container">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" :pulling-text="$t('refresh.pulling')" :loosing-text="$t('refresh.loosing')" :loading-text="$t('refresh.loading')">

        <van-list
                    v-model="loading"
                    :finished="finished"
                    :offset="50"
                    :immediate-check="false"
                    :finished-text="$t('video.no_more')"
                    @load="onLoad"
                >
                <div class="movie_list_n" v-if="movielist_1.length > 0">
            <div class="movie-list-n-item" v-for="(v,key) in movielist_1" :key="key" @click="toPlayVideo(v.id)">
              <div class="movie-list-n-title">
                {{v.name}}
              </div>
              <div class="movie-list-n-img" style="
              text-align: center;">
                <van-image
                fit="cover"
  position="center"
                  :src="v.img"
                  class="movie-list-n-img" :data-src="v.img"  alt=""  v-lazy="v.img"
                />
                <!-- <img> -->
                <div class="movie-list-n-lab">{{v.address}}</div>
              </div>
              <div class="movie-list-n-item-bottom">
                <div class="movie-n-time-div">
                 <img class="movie-list-ico-loves" v-for="item in 6" :key="item" src="../images/nHome/ico-love.png"  alt="" >
                  <div class="movie-list-notice">
                    <img class="movie-list-ico-notice" src="../images/nHome/ico-notice.png"  alt="" >
                    {{ v.comment_total }}
                  </div>
                  <div class="movie-list-addr">
                    <img class="movie-list-ico-addr" src="../images/nHome/ico-addr.png"  alt="" >
                    {{v.city_name}}
                  </div>
                  <div class="movie-list-money">
                    <img class="movie-list-ico-money" src="../images/nHome/ico-money.png"  alt="" >{{v.price}}</div>

                </div>
              </div>
            </div>
          </div>
   </van-list>

      </van-pull-refresh>

<img class="kefu" v-if="iskefu == '1'" src="@/assets/kefu.png" @click="toKefu" alt="" srcset="">
</div>



</template>

<script>
export default {
  data() {
    return {
      movielist_1:[],
      loading: false,
      finished: false,
      refreshing: false,
      isLoading:false,
      page:1,
      count:0,
      kefu: '', 
      iskefu: 0,
    };
  },
  methods:{
    toKefu() { window.location.href = this.kefu },
    gotoMenu(router){
      this.$router.replace(router)
    },
    toPlayVideo(id) {

      if(!localStorage.getItem('token') && this.$store.getters.getBaseInfo.video_login == 1){
        this.$router.push({path:'/Login'})
      } else {
        if (id) {
         this.$router.push({path:'/nHomeDetail?id='+id})
        }
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.page = 1
        // this.movielist_1 = []
        this.getBasicConfig();
        this.isLoading = false;
        this.$toast(this.$t('reservation.refresh'));
      }, 500);
    },
    onLoad() {
        this.loading = true;
        this.getBasicConfig();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.movielist_1 = [];
          this.refreshing = false;
        }
        // this.loading = false;
        if (this.movielist_1.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
    getBasicConfig(){
      this.$http({
        method: 'get',
        url: 'nHomeList',
        data:{page:this.page},
      }).then(res => {
        console.log(res.data)
        this.loading = false;
        if(this.page == 1){
          this.movielist_1 = res.data;
        }else{

          this.movielist_1 = this.movielist_1.concat(res.data);
        }
        if(res.data.length == 0){
          this.finished = true;
        }else{
          this.finished = false;
        }
        this.count = res.data.count;
        this.page++;
      })

    },

    query(selector) {
     return Array.from(document.querySelectorAll(selector));
    },
    lazyLoadImg() {
      if (this.movielist_1.length > 0) {
      this.query("img[data-src]").forEach((img) => {
          const rect = img.getBoundingClientRect();
          if (rect.top < window.innerHeight) {
            img.src = img.dataset.src;
            // 我们是通过img[data-src]查找所有img标签的，渲染后就删除data-src可减少forEach循环的计算成本
            img.removeAttribute("data-src");
          }
        });
      }
    }
  },
  mounted() {
    let that = this;
    window.addEventListener("scroll", () => {
      // 这里做一个 节流 操作
      that.lazyLoadImg()
    });
  },
  created() {
    this.$http({
        method: 'get',
        url: 'base_info'
    }).then(res => {
      if (res.code == 200) {

        if(!localStorage.getItem('token') && res.data.video_login == 1){
         return this.$router.push({path:'/Login'})
        }
        this.kefu = res.data.kefu 
        this.iskefu = res.data.iskefu
        this.isLoading = false
        this.$store.commit('setBaseInfoValue', res.data);
    this.getBasicConfig();
        }
      })
  }
}

</script>

<style lang='less' scoped>
@notice-bar-size:30px;
@movie-list-item-bottom-size:25px;
.linear-bg{
  height: 200px;
  background: linear-gradient(
      270deg,#e6c3a1,#f905e5);
}
.home-container{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.movie_list_n{
  display: flex;
  flex-wrap: wrap;
  margin: 10px;
  padding-bottom: 120px;
}
.movie-list-n-item{
 width: calc(50% - 11px);
 padding: 10px;
 background-color: #fff;
border: 1px solid #fff;
 color: #000;
 margin: 20px 5px;
 border-radius: 20px;
 padding: 10px;
}
.movie-list-n-item-bottom{

}
.movie-n-time-div{
margin-top: 20px;
}
.movie-list-n-title{
  // color: #f905e5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 20px;
  font-weight: bold;
}
.movie-list-n-img{
// width: 330px;
height: 330px;
position: relative;
}
.movie-list-n-lab{
  display: inline;
  position: absolute;
  top: 15px;
  left: 15px;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;  background-color: #f905e5;
  width: 315px;
  overflow: hidden;
}
.movie-list-ico-loves{
  display: inline-block;
  width: 40px;
}


.movie-list-notice{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-notice{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-addr{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-addr{
  width: 30px;
  display: inline-block;
  margin-right: 10px;
}
.movie-list-money{
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.movie-list-ico-money{
  width: 30px;
  display: inline-block;
  margin-right: 10px;

}

.kefu {
  position: fixed;
  bottom: 160px;
  right: 60px;
  width: 80px;
  height: 80px;
  z-index: 999;
  padding: 6px;
  box-sizing: border-box;
  background: #fff;border-radius: 50px;
}
</style>
